import 'bootstrap/dist/js/bootstrap.min.js';
// import 'lazysizes';
import {getCLS, getFID, getLCP} from 'web-vitals';

const PERF = false;

let imagesToLoad = document.querySelectorAll('img[data-src]');
function loadImages (image){
    image.setAttribute('src', image.getAttribute('data-src'));
    image.onload = () => {
        image.removeAttribute('data-src');
    };
}
imagesToLoad.forEach((img) => {
    loadImages(img);
});

if (PERF) performance.mark('startScript');
import("jquery/dist/jquery.slim.js").then(function ($) {
    "use strict";
    globalThis.jQuery = $;
    if (PERF) {
        performance.mark('loadedJQuery');
        performance.measure('measureLoadJQuery', 'startScript', 'loadedJQuery');
        getCLS(console.log, true);
        getFID(console.log); // Does not take a `reportAllChanges` param.
        getLCP(console.log, true);
        const resources = performance.getEntriesByType('resource')
            .map(r => r.initiatorType + ': ' + r.name + ' | ' + Math.round(r.responseEnd - r.startTime) + 'ms');
        // console.log(resources.join('\n'));
        /*        try {
                    const po = new PerformanceObserver((list) => {
                        let entries = list.getEntries();
                        // let lastEntry = perfEntries[perfEntries.length - 1];
                        // Process the latest candidate for largest contentful paint
                        console.log(entries);
                    });
                    // po.observe({ entryTypes: ['largest-contentful-paint'] });
                    po.observe({ type: 'layout-shift', buffered: true });
                } catch (e) {        }*/

    }
    $(window).scroll(function () {
        var window_top = $(window).scrollTop() + 1;
        if (window_top > 50) {
            $('.main_menu').addClass('menu_fixed animated fadeInDown');
        } else {
            $('.main_menu').removeClass('menu_fixed animated fadeInDown');
        }
    });
    import('owl.carousel').then(function () {
        var client_logo = $(".client_logo_slider");
        if (client_logo.length) {
            client_logo.owlCarousel({
                items: 7,
                loop: true,
                responsive: {
                    0: {
                        items: 3,
                        margin: 15
                    },
                    600: {
                        items: 5,
                        margin: 15
                    },
                    991: {
                        items: 7,
                        margin: 15
                    },
                    1200: {
                        items: 7,
                        margin: 15
                    }
                }
            });
        }

    });
});

import Isotope from "isotope-layout";

globalThis.Isotope = Isotope;
